.Screen {
  position: fixed;
  top: 65px;
  left: 250px;
  bottom: 0px;
  right: 0px;
  height: calc(100vh - 66px);
}

@media(max-width: 639px) {
  .Screen {
    left: 0;
    height: calc(100vh - 120px);
  }
}

.Screen-content {
  overflow: hidden;
  overflow-x: hidden;
  /* overflow-y: auto; */
}

.SideMenu {
  top: 80px;
}

.LeftSide {
  height: calc(100vh - 130px);
  padding-bottom: 20px;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  padding-top: 12px;
}

.CardGallery {
  display: flex;
  flex-wrap: wrap;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  align-content: flex-start;
  justify-content: flex-start;
}

.CardGallery::after {
  content: "";
  width: 16rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
}

.RightSide {
  height: calc(100vh - 130px);
  padding-bottom: 80px;
  overflow-y: auto;
  background-color: #f3f3f3;
  width: 100%;
  display: flex;
  flex-direction: column;
}
/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  .LeftSide {
    padding-bottom: 80px;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .LeftSide {
    padding-bottom: 80px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1112px)
and (max-device-width: 1112px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .LeftSide {
    padding-bottom: 80px;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1366px)
and (max-device-width: 1366px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .LeftSide {
    padding-bottom: 80px;
  }
}
