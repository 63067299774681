@import "../../assets/styles/index.css";

.MenuTab {
  border-top: 1px solid lightgray;
  border-left: solid #cb2e0b;
}

.MenuContent {
  min-height: 80vh;
  height: calc(100vh - 130px);
  border-left: 1px solid lightgray;
  overflow-y: scroll;
}

.SettingsContent {
  width: 400px;
}
