.Alert {
  position: absolute;
  background-color: #222222;
  color: white;
}

.left{
  bottom: 25px;
  left: 25px;
}

.right {
  bottom: 25px;
  right: 25px;
}
