.Navbar {
  position: fixed;
  top: 0;
  min-height: 65px;
  max-height: 65px;
}

.Navbar-search {
  background-color: #f3f3f3;
  border-color: #f3f3f3;
}

.Navbar-search:focus {
  background-color: white;
  border-color: #f3f3f3;
}
