.Messages-conversations {
  flex: 9;
}
.Messages-chat {
  flex: 10;
}

.Message.float-right {
  background-color: #d5f6d5 !important;
}

.Message-dropdown {
  position: relative;
  z-index: 2;
}

.Chat {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 130px;
  bottom: 0;
  z-index: 0;
}

.Chat-header {
  flex: 8;
}

.Chat-messages {
  flex: 40;
  background-color: #f3f3f3;
  overflow-y: scroll;
  padding: 12px;
  padding-right: 16px;
}

.Chat-send {
  flex: 1;
  background-color: #f3f3f3;
}

.Chat-textbox {
}
