input {
  height: 3rem;
  padding-left: 1rem !important;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  background-color: white;
}

input:focus {
  border: 1px solid #777777;
  outline: 0px;
}

/* hide dumb ass arrows in number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
  -moz-appearance: textfield; /* Firefox */
}
