@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./input.css";
@import "./tooltip.css";

body {
  margin: 0;
  font-family: "Open Sans", sans-serif, -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #222222;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --orange: #f2421b;
  --orange-100: #fdece8;
  --orange-200: #fabeb1;
  --orange-300: #f7937d;
  --orange-400: #f46748;
  --orange-500: "f2421b";
  --orange-600: "#e5340d";
  --orange-700: "#cb2e0b";
  --orange-800: "#b2280a";
  --orange-900: "#982208";
}

/* Orange Square Bullets */
ul.ul-orange {
  margin-left: 1.4em;
  list-style: none;
}
ul.ul-orange li::before {
  content: "\25A0"; /* \25A0 is the CSS Code/unicode for a square bullet */
  color: #f2421b;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  font-size: 1.4em;
  margin-left: -1em;
}

.right-panel {
  min-height: 95vh !important;
  background-color: #f3f3f3;
}

.RightPanel-large-image {
   object-fit: cover;
  /* max-width: 400px; */
}

.safari-rounded-fix {
  -webkit-mask-image: -webkit-radial-gradient(white, black);
}
