.SearchScreen > footer {
  position: absolute;
  bottom: 0;
  background-color: white;
  min-width: 40%;
}

/* ----------- iPad 1, 2, Mini and Air ----------- */
/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 1) {
  .SearchScreen > footer {
    bottom: 70px;
  }
}

/* ----------- iPad 3, 4 and Pro 9.7" ----------- */

/* Landscape */
@media only screen
and (min-device-width: 768px)
and (max-device-width: 1024px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .SearchScreen > footer {
    bottom: 70px;
  }
}

/* ----------- iPad Pro 10.5" ----------- */

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1112px)
and (max-device-width: 1112px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .SearchScreen > footer {
    bottom: 70px;
  }
}

/* ----------- iPad Pro 12.9" ----------- */

/* Landscape */
/* Declare the same value for min- and max-width to avoid colliding with desktops */
/* Source: https://medium.com/connect-the-dots/css-media-queries-for-ipad-pro-8cad10e17106*/
@media only screen
and (min-device-width: 1366px)
and (max-device-width: 1366px)
and (orientation: landscape)
and (-webkit-min-device-pixel-ratio: 2) {
  .SearchScreen > footer {
    bottom: 70px;
  }
}
