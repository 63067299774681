.angled-slab-img {
  height: 650px;
  object-fit: cover;
  /* overflow: hidden; */
  -webkit-clip-path: polygon(0 0, 0 650px, 650px 80px, 650px 0);
  clip-path: polygon(0 0, 0 650px, 650px 80px, 650px 0);
}

.video-container {
  overflow: hidden;
  position: relative;
  width:100%;
}

.video-container::after {
  padding-top: 56.25%;
  display: block;
  content: '';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.angled-slab-img-login {
  height: calc(100vh - 80px);
  object-fit: cover;
  /* overflow: hidden; */
  -webkit-clip-path: polygon(
    0 0,
    0 calc(100vh - 100px),
    calc(100vh - 100px) 80px,
    calc(100vh - 100px) 0
  );
  clip-path: polygon(
    0 0,
    0 calc(100vh - 100px),
    calc(100vh - 100px) 80px,
    calc(100vh - 100px) 0
  );
}

.example-image {
  float: right !important;
  /* width: 50vw !; */
  position: relative;
  height: auto;
}

.example-image.hero {
  margin-right: -15% !important;
}

.example-image.mobile {
  margin-right: -2% !important;
}
