.ListingsScreen {
  background-color: #f3f3f3;
  height: 100%;
}

.TableHeader {
  background-color: white;
}
.table-sticky th {
  position: sticky;
  top: 0;
  z-index: 2;
}

.table-sticky th:after,
.table-sticky th:before {
  content: "";
  position: absolute;
  left: 0;
  width: 100%;
}

.table-sticky th:before {
  top: -1px;
  border-top: 1.5px solid lightgrey;
}

.table-sticky th:after {
  bottom: -2px;
  border-top: 1.5px solid lightgrey;
}
